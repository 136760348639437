import styled, { css } from 'styled-components';
import { StylesConfig } from 'react-select';

interface ISelectContentProps {
  isErrored: boolean;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 5rem;

  margin-bottom: 5rem;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 360px;

  display: flex;
  flex-direction: column;

  background-color: var(--gray-800);

  padding: 2rem;
`;

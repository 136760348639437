import { useCallback, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { FiUser, FiLock } from 'react-icons/fi';

import { api } from '../../services/api';

import { getValidationErrors } from '../../utils/getValidationErrors';

import { Container, Title, Content } from './styles';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';

interface IFormDataSubmit {
  email: string;
  password: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required('E-mail obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().required('Senha obrigatória'),
});

const RemoveAccount = () => {
  const location = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const removeAccount = useCallback(
    async (email: string, password: string) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        await schema.validate({ email, password }, { abortEarly: false });

        const data = {
          email,
          password,
        };

        const responseSession = await api.post('/sessions_customer', data);

        if (responseSession.status === 201) {
          const { customer } = responseSession.data;
          console.log(customer.id);

          const responseDelete = await api.delete(
            `/customers/delete/${customer.id}`,
          );

          if (responseDelete.status === 204) {
            alert('Conta removida com sucesso');

            location.push('/');
          }
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [location],
  );

  const handleFormSubmit = useCallback(
    async ({ email, password }: IFormDataSubmit) => {
      const resultConfirm = confirm(
        `Deseja realmente remover sua conta?${'\n\n'}Esta operação removerá definitivamente sua conta.`,
      );

      if (resultConfirm) {
        await removeAccount(email, password);
      }
    },
    [removeAccount],
  );

  return (
    <Container>
      <Title>Remover conta</Title>

      <Content>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <Input name="email" icon={FiUser} placeholder="E-mail" />

          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Senha"
          />

          <Button loading={loading} type="submit">
            Remover contar
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export { RemoveAccount };
